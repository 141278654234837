
import React from "react";
import * as styles from "./Footer.module.scss";

import mailIcon from "../../assets/icons/emailIcon.svg";
import linkedinIcon from "../../assets/icons/linkedinIconWhite.svg";
import { Link } from "gatsby";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <a rel="noreferrer" href="mailto:hello@kolabs.fr">
                    <img className={styles.footerIcon} alt="Contacter kolabs" src={mailIcon} />
                </a>
                <a rel="noreferrer" href="https://www.linkedin.com/company/kolabs/">
                    <img className={styles.footerIcon} alt="Notre page linkedin" src={linkedinIcon} />
                </a>
                <Link to={"/mentionslegales"}>Mentions légales</Link>
                <Link to="/">
                    &copy; 2021 KOLABS
                </Link>
            </div>
        </footer>
    );
};

export default Footer;

// import React from "react";
// import * as styles from "./Footer.module.scss";

// import mailIcon from "../../assets/icons/emailIcon.svg";
// import linkedinIcon from "../../assets/icons/linkedinIconWhite.svg";

// const Footer = () => {
//     return (
//         <footer className={styles.footer}>
//             <div className={styles.footerContent}>
//                 <a href="mailto:hello@kolabs.fr">
//                     <img className={styles.footerIcon} alt="Contacter kolabs" src={mailIcon} />
//                 </a>
//                 <a href="https://www.linkedin.com/company/kolabs/">
//                     <img className={styles.footerIcon} alt="Notre page linkedin" src={linkedinIcon} />
//                 </a>
//                 <a href="https://www.google.com" target={"_blank"}>
//                     CGU
//                 </a>
//                 <a href="https://www.google.com" target={"_blank"}>
//                     Mentions légales
//                 </a>
//                 <a href="https://www.google.com" target={"_blank"}>
//                     Politique de confidentialité
//                 </a>
//                 <a href="https://www.google.com" target={"_blank"}>
//                     &copy; 2021 KOLABS
//                 </a>
//             </div>
//         </footer>
//     );
// };

// export default Footer;
